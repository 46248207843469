import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import trim from 'lodash/trim';

import { LanguageTuple, UseCurrentLangHook } from './model';

export const useCurrentLang: UseCurrentLangHook = (defaultLangPath = '/') => {
  const { pathname } = useLocation();

  const {
    allHomepage: { nodes },
    allSiteSettings: { nodes: settingsNodes },
  } = useStaticQuery(graphql`
    {
      allHomepage {
        nodes {
          id
          lang
          url
        }
      }
      allSiteSettings {
        nodes {
          id
          lang
          languageName
        }
      }
    }
  `);

  const languages = useMemo(
    (): LanguageTuple[] =>
      nodes
        .map(({ lang, url }) => {
          const langName =
            settingsNodes.find((node: { lang?: string }) => node.lang === lang)?.languageName ||
            null;

          return [lang, langName, url];
        })
        .sort(([, , a], [, , b]) => a.length - b.length),
    [nodes, settingsNodes]
  );

  const defaultLang = useMemo(
    (): LanguageTuple | undefined => languages.find(([, , url]) => url === defaultLangPath),
    [defaultLangPath, languages]
  );

  if (!defaultLang) {
    throw new Error(`
      Problem, can't find the default language.
      Try using the parameter, defaultLang to help find main page.
    `);
  }

  const urlLang = pathname.split('/')[1];

  const searchForCurrentLang = useMemo(
    () => languages.find(([, , url]) => trim(url, '/') === urlLang),
    [languages, urlLang]
  );

  const currentLang = searchForCurrentLang || defaultLang;

  return { languages, currentLang, defaultLang };
};
