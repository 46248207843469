import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { useCurrentLang } from 'hooks/useCurrentLang';
import { useLanguageSwitch } from 'hooks/useLanguageSwitch';

import Switch from 'common/Switch';

import { generateLangName } from './utils';

import './LanguageSwitch.scss';

const LanguageSwitch = (): JSX.Element | null => {
  const { list } = useLanguageSwitch();
  const { currentLang } = useCurrentLang();
  const languagesCount = list.length;

  if (languagesCount < 2) return null;

  const isChecked = Boolean(list.findIndex((lang) => lang.lang === currentLang[0]));
  const switchUrl = isChecked ? list[0].url : list[1].url;
  const switchLang = isChecked ? list[0].lang : list[1].lang;

  const handleOneTrust = () => {
    window.OneTrust?.changeLanguage(switchLang);
  };

  return (
    <ul className="language-switch">
      {list?.map(({ currentPage, url, langName, lang }, id): JSX.Element | null => {
        if (!lang) return null;

        return (
          <li key={url} className="language-switch__item">
            {id !== 0 ? (
              <Link to={switchUrl} onClick={handleOneTrust}>
                <Switch isChecked={isChecked} />
              </Link>
            ) : null}

            <Link
              className={classNames('header-main__link language-switch__lang-name', {
                'language-switch__lang-name--active': currentPage,
              })}
              to={url}
              onClick={handleOneTrust}
            >
              {langName || generateLangName(lang)}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default LanguageSwitch;
