import React, { FC } from 'react';
import loadable from '@loadable/component';
import classNames from 'classnames';

import 'styles/main.scss';

import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Loadable from 'components/Loadable';

import { LayoutProps } from './models';

const PreviewWidget = loadable(() => import('components/PreviewWidget'));

const Layout: FC<LayoutProps> = ({ children, headerTransparent, className, seo, urls }) => {
  const CSSclassNames = classNames('layout', className, {
    'layout--transparent': headerTransparent,
  });
  const shouldLoadPreviewWidget = Boolean(Number(process.env.GATSBY_ENABLE_PREVIEW_WIDGET));

  const { title, keywords, description } = seo || {};

  return (
    <div className={CSSclassNames}>
      <Loadable isActive={shouldLoadPreviewWidget}>
        <PreviewWidget cmsUrl={`${process.env.GATSBY_UMBRACO_BASE_URL}/umbraco`} />
      </Loadable>
      <Header />
      <main id="main" className="layout__main">
        {seo && urls ? (
          <>
            <Seo {...{ urls, seo }} />
            <PageSchema
              type="WebPage"
              name={title}
              data={{
                metaTitle: title,
                metaDescription: description,
                metaKeywords: keywords,
              }}
            />
          </>
        ) : null}
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
