import React, { FC } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useCurrentLang } from 'hooks/useCurrentLang';

import Logo from 'common/Logo';
import HeaderMenu from 'components/HeaderMenu';
import LanguageSwitch from 'components/LanguageSwitch';

import { HeaderProps } from './models';

import './Header.scss';

const Header: FC<HeaderProps> = () => {
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const {
    allHeader: { nodes },
  } = useStaticQuery(graphql`
    {
      allHeader {
        nodes {
          ...HeaderFragment
        }
      }
    }
  `);

  const headerMenuData = nodes.find(({ lang }) => lang === currentLangCode);

  const { navLinks, skipToContentText } = headerMenuData;

  return (
    <header>
      <Link className="header-skip-to-content" to="#main">
        <span className="header-skip-to-content__link">{skipToContentText}</span>
      </Link>
      <HeaderMenu {...{ headerMenuData }} />
      <div className="header-main">
        <div className="header-main__logo">
          <Logo />
        </div>
        <div className="header-main__navigation">
          {navLinks?.map(({ url, name }) => (
            <Link to={url} key={url} activeClassName="header-main__active-link">
              <span data-testid="item-test-id" className="header-main__link">
                {name}
              </span>
            </Link>
          ))}
          <LanguageSwitch />
        </div>
      </div>
    </header>
  );
};

export default Header;
