import React, { FC } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useCurrentLang } from 'hooks/useCurrentLang';

import Logo from 'common/Logo/Logo';

import { FooterProps } from './models';

import './Footer.scss';

const Footer: FC<FooterProps> = () => {
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const {
    allFooter: { nodes },
  } = useStaticQuery(graphql`
    {
      allFooter {
        nodes {
          ...FooterFragment
        }
      }
    }
  `);

  const { copyright, navLinks, legalMentions } = nodes.find(({ lang }) => lang === currentLangCode);

  return (
    <footer className="footer">
      <div className="footer__legals">{legalMentions}</div>
      <div className="footer__content">
        <div className="footer__logo">
          <Logo isFooter />
        </div>
        <div className="footer__links">
          {navLinks.map(({ name, url }) => (
            <div className="footer__link" key={url}>
              <Link to={url}>{name}</Link>
            </div>
          ))}
        </div>
      </div>
      <span className="footer__copyright">{copyright}</span>
    </footer>
  );
};

export default Footer;
