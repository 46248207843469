import React, { FC } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useCurrentLang } from 'hooks/useCurrentLang';

import Image from 'common/Image';

import { LogoProps } from './models.d';

import './Logo.scss';

const Logo: FC<LogoProps> = ({ isFooter }) => {
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const {
    allBrandSettings: { nodes: brandSettingsNodes },
    allHomepage: { nodes: homepageNodes },
  } = useStaticQuery(graphql`
    {
      allBrandSettings {
        nodes {
          brandName
          brandLogo {
            childImageSharp {
              gatsbyImageData(width: 144, placeholder: BLURRED, quality: 100)
            }
          }
          footerLogo: brandLogo {
            childImageSharp {
              gatsbyImageData(width: 144, placeholder: BLURRED, quality: 99)
            }
          }
          lang
        }
      }
      allHomepage {
        nodes {
          url
          lang
        }
      }
    }
  `);

  const { brandLogo, footerLogo, brandName } = brandSettingsNodes.find(
    ({ lang }) => lang === currentLangCode
  );

  const { url } = homepageNodes.find(({ lang }) => lang === currentLangCode);

  return (
    <Link to={url} className="logo">
      <Image imageData={isFooter ? footerLogo : brandLogo} alt={brandName} className="logo__img" />
    </Link>
  );
};

export default Logo;
