import React, { FC } from 'react';
import classNames from 'classnames';

import { SwitchProps } from './models.d';

import './Switch.scss';

const Switch: FC<SwitchProps> = ({ isChecked }) => {
  const switchClasses = classNames('switch', {
    'switch--checked': isChecked,
  });

  return <span className={switchClasses} data-testid="switch-item" />;
};

export default Switch;
