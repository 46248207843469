import { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import { useCurrentLang } from 'hooks/useCurrentLang';

import { AllSitePageResult, UseLanguageSwitchHook } from './model';

export const useLanguageSwitch: UseLanguageSwitchHook = () => {
  const {
    languages,
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const { pathname } = useLocation();

  const {
    allSitePage: { nodes },
    allSiteSettings: { nodes: settingsNodes },
  }: AllSitePageResult = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          id
          path
          context {
            refId
            lang
          }
        }
      }
      allSiteSettings {
        nodes {
          lang
          languageName
        }
      }
    }
  `);

  const currentPage = useMemo(
    () => nodes.find(({ path }) => path === pathname) || null,
    [nodes, pathname]
  );

  const list = useMemo(() => {
    const filteredNodes = nodes.filter(
      (node) => node.context?.refId === currentPage?.context.refId
    );

    return languages.map(([lang, langName, url]) => {
      const findNode = filteredNodes.find((node) => node.context?.lang === lang);

      if (findNode) {
        const language = findNode.context?.lang;
        const languageName =
          settingsNodes.find(
            (node: { lang?: string }) => node.lang?.toLowerCase() === language?.toLowerCase()
          )?.languageName || null;

        return {
          currentPage: currentLangCode === language,
          lang: language,
          langName: languageName,
          url: findNode.path,
        };
      }

      return {
        currentPage: currentLangCode === lang,
        lang,
        langName,
        url,
      };
    });
  }, [currentLangCode, currentPage, languages, nodes, settingsNodes]);

  return { list, currentPage };
};
