import React, { createRef, FC, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import LanguageSwitch from 'components/LanguageSwitch';
import { isBrowser } from 'utils/browser';

import { HeaderMenuProps } from './models';

import './HeaderMenu.scss';

const HeaderMenu: FC<HeaderMenuProps> = ({
  headerMenuData: { closeButtonAltText, openButtonAltText, navLinks },
}) => {
  const [navbarOpen, setNavbarOpen] = useState<boolean>(false);

  const handleNavbarToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  const menuRef = createRef<HTMLUListElement>();
  useEffect(() => {
    const checkTouchend = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setNavbarOpen(false);
      }
    };
    document.addEventListener('touchend', checkTouchend);

    return () => document.removeEventListener('touchend', checkTouchend);
  });

  useEffect(() => {
    if (!isBrowser()) return;
    const bodyElement = window.document.body;

    if (navbarOpen) {
      bodyElement.classList.add('scroll--prevent');
    } else {
      bodyElement.classList.remove('scroll--prevent');
    }
  }, [navbarOpen]);

  return (
    <nav className="header-menu" data-testid="header-menu">
      <button
        className="fas fa-bars-open header-menu__burger-button"
        aria-label={openButtonAltText}
        onClick={handleNavbarToggle}
        type="button"
      />
      <ul
        ref={menuRef}
        className={classNames('header-menu__menu-navigation', {
          'header-menu__show-menu': navbarOpen,
        })}
        data-testid="menu-navigation"
      >
        <ul className="header-menu__top">
          <li key={closeButtonAltText}>
            <button
              aria-label={closeButtonAltText}
              className="fas fa-bars-closed header-menu__close-button"
              onClick={handleNavbarToggle}
              type="button"
            />
          </li>
          {navLinks?.map(({ name, url }) => (
            <li key={name}>
              <div className="item">
                <Link to={url} key={url} onClick={handleNavbarToggle}>
                  <span className="link">{name}</span>
                </Link>
              </div>
            </li>
          ))}
        </ul>
        <li className="header-menu__bottom">
          <LanguageSwitch />
        </li>
      </ul>
    </nav>
  );
};

export default HeaderMenu;
